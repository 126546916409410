import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FixedAmount, SecureLogos } from "../Components";
import QRCode from "react-qr-code";

import {
  CheckoutContext,
  DispatchContext,
} from "../../context/CheckoutContext";
import Assets from "../../assets/Assets";

import "./styles.scss";

export const WalletAddress = (props) => {
  const { data } = useContext(CheckoutContext);
  const { cancelPayment } = useContext(DispatchContext);
  const { t } = useTranslation();

  const addressToClipboard = () => {
    navigator.clipboard.writeText(data.externalRefs.address);
  };

  return (
    <>
      <form>
        <div className="navbar close-only">
          {data.showCancelButton && (
            <div className="button-cancel" onClick={() => cancelPayment()}>
              <img src={Assets.close} alt="close" />
            </div>
          )}
        </div>
        <img
          className="inner-form-logo"
          src={Assets["zinzipay"]}
          alt="zinzipay"
        />

        <div className="crypto-container">
          <FixedAmount additionalClass="wallet" />

          <div className="deposit-to">{t("walletDepositMessage")}</div>
          <div className="address">
            <p>{data.externalRefs.address}</p>
            <div
              className={"copy-to-clipboard-button"}
              onClick={addressToClipboard}
            ></div>
          </div>
          {data.externalRefs.blockchain && (
            <p className="blockchain">
              Blockchain: <span>{data.externalRefs.blockchain}</span>
            </p>
          )}
          <div className="wallet-address-qr">
            <QRCode value={data.externalRefs.address} size={304} />
          </div>

          <div className="info-wrapper">
            <div className="info-icon"></div>
            <div className="info-mesage">{t("cryptoWarningMessage_1")}</div>
          </div>

          {data.showBackToShopButton && (
            <a className="link-return-shop" href={data.returnUrl}>
              {t("backToShopButtonLabel")}
            </a>
          )}
        </div>
      </form>
      <SecureLogos />
    </>
  );
};
