import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Assets from "../../assets/Assets";
import "./style.scss";
import { Method } from "./Method";
import { CheckoutContext } from "../../context/CheckoutContext";

export const MethodGroup = ({ amount }) => {
  const { data } = useContext(CheckoutContext);

  const [methods, setMethods] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setMethods(() => {
      let listItems = [];
      data.checkoutOptions.forEach((element) => {
        listItems.push(
          <Method
            key={element.paymentMethod}
            method={element}
            amount={amount}
          />
        );
      });

      return listItems;
    });
  }, [data.checkoutOptions]);

  return (
    <>
      <img className="logo" src={Assets["zinzipay"]} alt="zinzipay" />
      {data.checkoutOptions.length > 0 ? (
        <>
          <header className="header-methods">
            {t("SelectPaymentMethodLabel")}
          </header>
          <div className="method-group">{methods}</div>
        </>
      ) : (
        <header className="header-methods">
          {t("PaymentMethodNotAvailableLabel")}
        </header>
      )}
    </>
  );
};
