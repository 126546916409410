import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckoutContext,
  DispatchContext,
} from "../../context/CheckoutContext";
import Assets from "../../assets/Assets";
import { formatAmount } from "../../utils/CurrencyFormater";
import { SecureLogos } from "../Components";

import "./styles.scss";

export const Confirmation = (props) => {
  const { data, method } = useContext(CheckoutContext);
  const { confirmRequest, cancelPayment } = useContext(DispatchContext);
  const { t } = useTranslation();

  const browserLanguage = navigator.language || navigator.userLanguage;

  return (
    <>
      <div className="confirmation-wrapper">
        <div className="navbar">
          {data.showCancelButton && (
            <div className="button-cancel" onClick={() => cancelPayment()}>
              <img src={Assets.close} alt="cancel" />
            </div>
          )}
        </div>
        <img className="logo" src={Assets["zinzipay"]} alt="zinzipay" />

        <div className="method-description">
          <div className="method-label">
            {typeof method === "object" ? t(method.paymentMethod) : t(method)}
          </div>
          {data.description && (
            <span className="description-text">{data.description}</span>
          )}
        </div>

        <p className="confirm-message">{t("ConfirmationMessage")}</p>
        <div className="amounts-list">
          {data.customerAmount && data.customerCurrency && (
            <p className="confirm-amount-currency">
              {t("depositAmountLabel")}&nbsp;
              <span>
                {formatAmount(data.customerAmount, data.customerCurrency)}
              </span>
            </p>
          )}
          {data.amount && data.commission && (
            <p className="confirm-amount-currency">
              {data.customerAmount && t("commissionLabel")}&nbsp;
              <span>{formatAmount(data.commission, data.currency)}</span>
            </p>
          )}
          {data.amount && (
            <p className="confirm-amount-currency">
              {data.customerAmount && t("debitAmountLabel")}&nbsp;
              <span>{formatAmount(data.amount, data.currency)}</span>
            </p>
          )}
        </div>

        <input
          className="button-submit"
          type="button"
          onClick={() => confirmRequest()}
          value={t("ButtonConfirmLabel")}
        />
      </div>
      <SecureLogos />
    </>
  );
};
