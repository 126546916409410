import zinzipay from "./img/zinzipay-logo.svg";
import zinzipay_footer from "./img/zinzipay-footer-logo.svg";
import chip from "./img/chip.png";
import back from "./img/back.png";
import close from "./img/close.png";
import copy from "./img/copy.png";
import load from "./img/load.png";
import qr_code_icon from "./img/qr-code-icon.png";
import notofication_icon from "./img/notofication_icon.svg";
import arrow_icon_white from "./img/arrow_white.png";
import visa_icon from "./img/visa.svg";
import mastercard_icon from "./img/mastercard.svg";
import save_card_icon from "./img/icons/save-card-icon.png";

export default {
  zinzipay,
  zinzipay_footer,
  chip,
  back,
  close,
  copy,
  load,
  qr_code_icon,
  notofication_icon,
  arrow_icon_white,
  visa_icon,
  mastercard_icon,
  save_card_icon,
};
