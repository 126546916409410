import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SecureLogos } from "../Components";
import Assets from "../../assets/Assets";
import { CleaveInput } from "../input/CleaveInput";
import { ReactComponent as LockIcon } from "../../assets/img/form-icons/material-symbols_lock.svg";
import { FormProvider } from "react-hook-form";

import "./styles.scss";
import {
  CheckoutContext,
  DispatchContext,
} from "../../context/CheckoutContext";

export const ThreeDS = ({ onSubmit, form }) => {
  const { data } = useContext(CheckoutContext);
  const { cancelPayment } = useContext(DispatchContext);
  const { t } = useTranslation();

  function validateOTP(value, name) {
    let error;
    if (!value) {
      error = `${t(name)}`;
    }
    if (value.length < 5) {
      error = `${t("invalidOTP")}`;
    }

    return error || true;
  }

  return (
    <>
      <div className="wrapper-state wrapper-threeds">
        <div className="navbar">
          <img
            className="inner-form-logo"
            src={Assets["zinzipay"]}
            alt="Zinzipay"
            style={{ marginTop: "0px" }}
          />
          {data.showCancelButton && (
            <div className="button-cancel" onClick={() => cancelPayment()}>
              <img src={Assets.close} alt="cancel" />
            </div>
          )}
        </div>
        <h2>3DSecure</h2>
        <div className="state-threeds state-threeds__merchant">
          <p className="state-threeds__title">Merchant:</p>
          <p>3DSEmulator</p>
        </div>
        <div className="state-threeds state-threeds__amount">
          <p className="state-threeds__title">{t("AmountLabel")}:</p>
          <p>{data.amount}</p>
        </div>

        <FormProvider {...form}>
          <form onSubmit={onSubmit} className="threeds-form">
            <div className="state-threeds state-threeds__otp">
              <CleaveInput
                options={{
                  blocks: [5],
                  numericOnly: true,
                }}
                name="oneTimePassword"
                id="otp"
                label="PasswordLabel"
                placeHolder=""
                type="tel"
                inputmode="numeric"
                autocomplete="cc-csc"
                inputClass="otp-secure"
                wrapperClass="card-field-wrapper cvv-wrapper"
                inputValidation={(value) => validateOTP(value, "OTPRequired")}
                additionalChild={<LockIcon className={`left-slot }`} />}
              />
            </div>
            <input
              className="button-submit"
              style={{ width: "100%" }}
              type="submit"
              value="Submit"
            />
          </form>
        </FormProvider>
      </div>
      <SecureLogos />
    </>
  );
};
